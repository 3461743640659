import styled, { css } from "styled-components";

const containerMixin = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  gap: 32px;
`;

export const HamburgerContainer = styled.div``;

export const MenuWithHamburger = styled.div`
  ${containerMixin}
  justify-content: space-evenly;

  svg {
    flex-shrink: 0;

    @media (max-width: 550px) {
      width: 10vw;
      height: 10vw;
    }

    @media (min-width: 550px) {
      width: 7vw;
      height: 7vw;
    }

    @media (min-width: 1030px) {
      width: 5vw;
      height: 5vw;
    }

    /* @media (max-width: 1250px) {
      width: 12.5vw;
      height: 12.5vw;
    } */
  }
`;

export const OriginalMenu = styled.div`
  ${containerMixin}
  padding: 0 130px;
`;

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 108px;
  background-color: #f4f4f4;

  @media (max-width: 1250px) {
    ${MenuWithHamburger} {
      display: flex;
    }

    ${OriginalMenu} {
      display: none;
    }
  }

  @media (min-width: 1250px) {
    ${MenuWithHamburger} {
      display: none;
    }

    ${OriginalMenu} {
      display: flex;
    }
  }
`;
