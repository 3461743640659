import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  display: flex;
  /* justify-content: center;
  align-items: center; */
  width: 100%;
  height: 870px;
  margin-top: 108px;
  background-color: #181818;
`;

export const ImageComposer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
`;

export const Left = styled.div`
  display: flex;
  align-items: flex-end;
  width: 41vw;
  height: 100%;
  /* flex-shrink: 0; */
  background-color: transparent;
  background-image: url("/images/human-hand.svg");
  background-repeat: no-repeat, no-repeat;
  background-position: bottom, left;
  background-size: 100%;
`;

export const Right = styled.div`
  display: flex;
  width: 41vw;
  height: 100%;
  /* flex-shrink: 0;
  flex-grow: 1; */
  background-image: url("/images/robot-hand.svg");
  background-repeat: no-repeat, no-repeat;
  background-position: top, right;
  background-size: 100%;
  background-color: transparent;
`;

export const Title = styled.h1`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 106px;
  right: 125px;
  font-family: PressStart2P;
  font-weight: 400;
  font-size: 7vw;
  line-height: 96px;
  color: #f4f4f4;
`;

export const Description = styled.div`
  position: absolute;
  width: 45%;
  top: 106px;
  left: 131px;
  font-family: PressStart2P;
  font-weight: 400;
  font-size: 2vw;
  line-height: 42px;
  text-align: justify;
  color: #f4f4f4;
`;
