import React from "react";
import { hooks } from "../../shared";
// import { Menu } from "../menu";
import * as Markup from "./footer.styles";

export const Footer = React.memo(() => {
  const { handlePhoneClick } = hooks.useHandlePhoneClick();

  return (
    <Markup.Container>
      <div>Все права защищены</div>
      <div>© Vangog-IT. ОГРНИП 323774600438338. ИНН 772270222393</div>
      <Markup.Phone onClick={handlePhoneClick}>+7 (925) 374 46 43</Markup.Phone>
    </Markup.Container>
  );
});


{/* <Markup.Left>
<Markup.Title>
  VANGOG
</Markup.Title>
</Markup.Left>
<Markup.Center>
<Menu />
</Markup.Center>
<Markup.Right>
<Markup.Social>
  <Markup.SocialTitle>
    Наши соцсети
  </Markup.SocialTitle>
  <Markup.Icons>
    <Markup.Link target="_blank" rel="noopener noreferrer" href="https://t.me/varkhn">
      <Markup.TgIcon />
    </Markup.Link>
    <Markup.Link href={phoneLinkHref}>
      <Markup.PhoneIcon />
    </Markup.Link>
    <Markup.Link target="_blank" rel="noopener noreferrer" href="https://vk.com/v.arkhn">
      <Markup.VkIcon />
    </Markup.Link>
  </Markup.Icons>
</Markup.Social>
<Markup.Link href={phoneLinkHref}>
  +7 (925) 374-46-43
</Markup.Link>
</Markup.Right>
<Markup.Link href={phoneLinkHref}>
  +7 (925) 374-46-43
</Markup.Link>
</Markup.Right>
<Markup.Link href={phoneLinkHref}>
  +7 (925) 374-46-43
</Markup.Link>
</Markup.Right>
<Markup.Link href={phoneLinkHref}>
  +7 (925) 374-46-43
</Markup.Link>
</Markup.Right> */}