import React from "react";
import * as Components from "./components";

export const App = React.memo(() => {
  return (
    <>
      <Components.Banner />
      {/* <Components.About />
      <Components.Advantages />
      <Components.Services /> */}
      <Components.Footer />
      <Components.Header />
    </>
  );
});
