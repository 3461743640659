import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  height: 715px;
  background-color: #81b96e;
`;

export const Left = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
  width: 50%;
  padding-right: 20px;
`;

export const Right = styled.div`
  display: flex;
  flex-shrink: 0;
  width: 50%;
  background-image: url("/images/team.png");
  background-repeat: no-repeat, no-repeat;
  background-position: bottom, left;
  background-size: 100%;
  background-color: transparent;
`;

export const TextBlock = styled.div`
  display: flex;
  width: 569px;
  height: 561px;
  background-color: transparent;
`;

export const TextContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 543px;
  height: 540px;
  gap: 45px;
  background-color: #f4f4f4;
`;

export const Title = styled.h2`
  font-family: PressStart2P;
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 36px;
  color: #000000;
`;

export const Description = styled.div`
  width: 439px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
`;

export const BorderBlock = styled.div`
  position: absolute;
  transform: translate(26px, 21px);
  width: 543px;
  height: 540px;
  border: 2px solid #b82424;
`;
