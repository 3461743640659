import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  background-color: #f4f4f4;
  padding: 24px 130px;
`;

export const Phone = styled.button`
  text-align: left;
  background-color: transparent;
  border: none;
  outline: none;

  :hover {
    background-color: transparent;
  }
`;

export const Left = styled.div`
  margin-right: 72px;
  padding-top: 33px;
`;

export const Center = styled.div`
  display: flex;
  height: 100%;
  align-items: flex-end;
  margin-right: 150px;
`;

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Title = styled.h2`
  font-family: PressStart2P;
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 48px;
  color: #181818;

  &:hover {
    cursor: pointer;
  }
`;

export const Social = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const SocialTitle = styled.div`
  font-family: PTSansCaptionRegular;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  color: #000000;
`;

export const Link = styled.a`
  font-family: PTSansCaptionRegular;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 31px;
  color: #000000;
  padding: 0;
  margin: 0;
  text-decoration: none;

  &:visited {
    color: #000000;
  }
`;

const iconBase = css`
  width: 60px;
  height: 60px;
  background-repeat: no-repeat, no-repeat;
  background-position: center, center;
  background-size: 100%;
  border-radius: 4px;
`;

export const Icons = styled.div`
  display: flex;
  gap: 15px;
`;

export const TgIcon = styled.div`
  background-image: url("/images/tg.png");
  ${iconBase}
`;

export const PhoneIcon = styled.div`
  background-image: url("/images/phone.png");
  ${iconBase}
`;

export const VkIcon = styled.div`
  background-image: url("/images/vk.png");
  ${iconBase}
`;
