import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 70px;
`;

export const Item = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 31px;
  color: #000000;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  &:hover {
    cursor: pointer;
  }
`;
