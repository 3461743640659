import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 649px;
  gap: 69px;
  background-color: #181818;
`;

export const Title = styled.h2`
  font-family: PressStart2P;
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 36px;
  color: #f4f4f4;
`;

export const Advantages = styled.div`
  display: flex;
  gap: 20px;
`;

export const Advantage = styled.div`
  display: flex;
  align-items: center;
  width: 280px;
  height: 300px;
  padding: 0 11px;
  background-color: #f4f4f4;
  color: #000000;
`;
