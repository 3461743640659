import React from "react";
import Hamburger from "hamburger-react";
import { hooks } from "../../shared";
import { Menu } from "../menu";
import { MobileMenu } from "../mobile-menu";
import { ReactComponent as PhoneIcon } from "./phone.svg";
import * as Components from "./components";
import * as Markup from "./header.styles";

export const Header = React.memo(() => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { handlePhoneClick } = hooks.useHandlePhoneClick();

  const handleHamburgerToggle = React.useCallback((isToggle: boolean) => {
    setIsOpen(isToggle)
  }, []);

  return (
    <Markup.Container id="header">
      <Markup.MenuWithHamburger>
        {isOpen && <MobileMenu />}
        <Hamburger toggled={isOpen} onToggle={handleHamburgerToggle} />
        <Components.Heading />
        <PhoneIcon onClick={handlePhoneClick} />
      </Markup.MenuWithHamburger>
      <Markup.OriginalMenu>
        <Components.Heading />
        <Menu />
        <Components.LinkUs />
      </Markup.OriginalMenu>
    </Markup.Container>
  );
});
