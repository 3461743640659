import React from "react";
import { constants, hooks } from "../../shared";
import * as Markup from "./menu.styles";

export const Menu = React.memo(() => {
  const { handlers } = hooks.useMenu();

  return (
    <Markup.Container>
      {constants.linksOrder.map((key) => {
        const link = constants.links[key];

        return <Markup.Item key={key} onClick={handlers[key]}>{link.displayName}</Markup.Item>
      })}
    </Markup.Container>
  );
});
