import styled from "styled-components";

export const Container = styled.h2`
  display: flex;
  align-items: center;
  font-family: PressStart2P;
  font-weight: 400;
  user-select: none;

  @media (max-width: 1250px) {
    font-size: 5vw;
    line-height: 5vw;
  }

  @media (min-width: 1250px) {
    font-size: 36px;
    line-height: 36px;
  }

  &:hover {
    cursor: pointer;
  }
`;
