import React from "react";
import * as Markup from "./banner.styles";

export const Banner = React.memo(() => {
  return (
    <Markup.Container>
      <Markup.Description>
        Решения для будущего, созданные сегодня
      </Markup.Description>
      <Markup.ImageComposer>
        <Markup.Left />
        <Markup.Right />
      </Markup.ImageComposer>
      <Markup.Title>
        VANGOG
      </Markup.Title>
    </Markup.Container>
  );
});
