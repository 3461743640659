import { LinkType, LinkInfo } from "./types";

export const links: Record<LinkType, LinkInfo> = {
  // [LinkType.about]: {
  //   displayName: "О студии",
  //   href: "#about",
  // },
  // [LinkType.services]: {
  //   displayName: "Услуги",
  //   href: "#services",
  // },
  [LinkType.apps]: {
    displayName: "Портфолио",
    href: "https://apps.vangog-it.ru",
    target: "_blank",
    rel: "noopener",
  },
};

export const linksOrder = [
  // LinkType.about,
  // LinkType.services,
  LinkType.apps,
];
