import styled from "styled-components";

export const Container = styled.button`
  width: 280px;
  min-width: 100px;
  height: 63px;
  padding: 0 16px;
  font-family: PTSansCaptionBold;
  background-color: #81b96e;
  color: #f4f4f4;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  border-radius: ${({ theme }) => theme.borderRadius};
`;
