import { links, linksOrder } from "./constants";
import { LinkType } from "./types";

export const useMenu = () => {
  const createMenuItemClickHandler = (key: keyof typeof LinkType) => () => {
    const linkInfo = links[key];

    if (!linkInfo) return;

    const link = document.createElement("a");
    link.href = linkInfo.href;

    if (linkInfo.target) {
      link.target = linkInfo.target;
    }

    if (linkInfo.rel) {
      link.rel = linkInfo.rel;
    }

    link.click();
    link.remove();
  };

  const handlers = linksOrder.reduce((acc, linkKey) => {
    acc[linkKey] = createMenuItemClickHandler(linkKey);

    return acc;
  }, {} as Record<LinkType, ReturnType<typeof createMenuItemClickHandler>>);

  return {
    handlers,
  };
};

export const useHandlePhoneClick = () => {
  const handlePhoneClick = () => {
    const link = document.createElement("a");

    link.href = "tel:+79253744643";
    link.click();
    link.remove();
  };

  return {
    handlePhoneClick,
  };
};
