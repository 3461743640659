export interface LinkInfo {
  displayName: string;
  href: string;
  target?: string;
  rel?: string;
}

export enum LinkType {
  // about = "about",
  // services = "services",
  apps = "apps",
}
